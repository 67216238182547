import React from "react"
import BackgroundImage from "gatsby-background-image"

import styles from "./background.module.css"

export default function Background({ children, background }) {
  return (
    <BackgroundImage
      className={`${styles.background}`}
      Tag="section"
      fluid={background}
      backgroundColor={`#de1929`}
    >
      {children}
    </BackgroundImage>
  )
}
