import React from "react"

import styles from "./download.module.css"

import AppStoreBtn from "../../../images/app-store.svg"
import GooglePlayBtn from "../../../images/google-play.svg"

import { itunesLink, googlePlayLink } from "../../../constants/links"

export default function Download() {
  return (
    <nav className={styles.download}>
      <a href={itunesLink} className={`${styles.button} ${styles.buttonApple}`}>
        <img
          src={AppStoreBtn}
          alt="Puma FastPay App Store"
          className={styles.buttonImg}
        />
      </a>
      <a
        href={googlePlayLink}
        className={`${styles.button} ${styles.buttonGoogle}`}
      >
        <img
          src={GooglePlayBtn}
          alt="Puma FastPay Google Play"
          className={styles.buttonImg}
        />
      </a>
    </nav>
  )
}
