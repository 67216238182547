import React from "react"
import Img from "gatsby-image/withIEPolyfill"

import styles from "./image.module.css"

export default function Image({ image, alt }) {
  return (
    <Img fluid={image} alt={`Puma FastPay ${alt}`} className={styles.image} />
  )
}
