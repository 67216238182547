import React from "react"
import { useTranslation } from "react-i18next"

/* Components */
import SEO from "../components/seo/seo"
import Wrapper from "../components/wrapper/wrapper"
import Background from "../components/bad-bunny/background/background"
import Image from "../components/bad-bunny/image/image"
import Download from "../components/bad-bunny/download/download"

export default function BBPage({ location, data }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.bb.title")
  const PageDescription = t("pages.bb.description")
  const PageShareImage = t("pages.bb.share-image")

  return (
    <Wrapper>
      <SEO
        title={PageTitle}
        description={PageDescription}
        image={PageShareImage}
      />
      <Background background={data.background.childImageSharp.fluid}>
        <Image image={data.slice1.childImageSharp.fluid} alt={PageTitle} />
        <Image image={data.slice2.childImageSharp.fluid} alt={PageTitle} />
        <Image image={data.slice3.childImageSharp.fluid} alt={PageTitle} />
        <Download />
        <Image image={data.slice4.childImageSharp.fluid} alt={PageTitle} />
      </Background>
    </Wrapper>
  )
}

export const query = graphql`
  query BBQuery {
    slice1: file(relativePath: { eq: "bb/slice1.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    slice2: file(relativePath: { eq: "bb/slice2.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    slice3: file(relativePath: { eq: "bb/slice3.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    slice4: file(relativePath: { eq: "bb/slice4.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    background: file(relativePath: { eq: "bb/bkg.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
